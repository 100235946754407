import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { NavDropdown } from 'react-bootstrap';
import { MenuItem } from 'react-bootstrap';
import Icon from 'react-fa';
import localForage from 'localforage';
import _ from 'lodash';
import { t } from 'ttag';
import { getLogoImage } from 'localizableAssets';

const logoImage = getLogoImage();

type Props = {
  customer: string;
  webRegistration: string | null;
  version: string;
};

export default class UnauthenticatedNavBar extends React.Component<Props> {
  renderToggleButton() {
    return (
      <div>
        <span className="sr-only">{t`Pokaži meni`}</span>
        <span className="icon-bar" />
        <span className="icon-bar" />
        <span className="icon-bar" />
      </div>
    );
  }

  renderBrand() {
    return (
      <a className="navbar-brand" href="/">
        <img
          src={__DEV__ ? logoImage.logoDev : logoImage.logo}
          width={logoImage.width}
          height={logoImage.height}
          alt={t`PlanDela`}
          className="logo-image"
        />
      </a>
    );
  }

  renderRightDropdown() {
    const customerSite = window.location.hostname;

    const supportHref = t`mailto:podpora@plandela.si?subject=Podpora za ${customerSite}`;
    return (
      <NavDropdown
        id="right-dropdown"
        className="right-dropdown"
        title={
          <CustomerInfo
            customer={this.props.customer}
            webRegistration={this.props.webRegistration}
          />
        }
      >
        <MenuItem onSelect={this.handleClearLocalData}>
          {t`Počisti lokalne podatke`}
        </MenuItem>
        <CustomMenuItem role="presentation" className="divider" />
        <MenuItem target="_blank" href="/help/">
          {t`Pomoč`}
        </MenuItem>
        <MenuItem href={supportHref}>{t`Podpora`}</MenuItem>
        <CustomMenuItem role="presentation" className="divider" />
        <CustomMenuItem className="small dropdown-text">
          <div>Ver. {this.props.version}</div>
        </CustomMenuItem>
      </NavDropdown>
    );
  }

  renderRightNav() {
    return (
      <Nav navbar pullRight>
        {this.renderRightDropdown()}
      </Nav>
    );
  }

  render() {
    return (
      <Navbar fixedTop fluid className="navbar navbar-custom">
        <Navbar.Header>
          <Navbar.Brand>{this.renderBrand()}</Navbar.Brand>
          <Navbar.Toggle>{this.renderToggleButton()}</Navbar.Toggle>
        </Navbar.Header>
        <Navbar.Collapse>{this.renderRightNav()}</Navbar.Collapse>
      </Navbar>
    );
  }

  handleClearLocalData = () => {
    localForage.getItem('dbInstances').then(instances => {
      _.each(_.keys(instances || {}), name =>
        localForage.createInstance({ name }).clear()
      );
    });
  };
}

type CustomMenuItemProps = {
  role?: React.AriaRole;
  className?: string;
  children?: React.ReactNode;
};

const CustomMenuItem = ({ role, className, children }: CustomMenuItemProps) => (
  <li role={role} className={className}>
    {children}
  </li>
);

type CustomerInfoProps = {
  customer: string;
  webRegistration: string | null;
};

const CustomerInfo = ({ customer, webRegistration }: CustomerInfoProps) => (
  <span>
    {webRegistration && (
      <span style={{ marginRight: 20 }}>
        <Icon name="flag" /> &nbsp;
        {webRegistration}{' '}
      </span>
    )}
    <Icon name="building-o" /> &nbsp;
    {customer}
  </span>
);
